import ACOLink from '@components/ACOLink/ACOLink';
import ACOTooltip from '@components/ACOTooltip/ACOTooltip';
import { Avatar } from '@components/Avatar/Avatar';
import { CreatePost } from '@components/modal/CreatePost/CreatePost';
import NotificationsLayout from '@components/Notifications/NotificationsLayout';
import { AccountCircleOutlined, ExitToAppOutlined, ForumOutlined, SvgIconComponent } from '@mui/icons-material';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
import Assignment from '@mui/icons-material/AssignmentOutlined';
import CardGiftcardOutlinedIcon from '@mui/icons-material/CardGiftcardOutlined';
import CloseIcon from '@mui/icons-material/Close';
import CoPresentOutlinedIcon from '@mui/icons-material/CoPresentOutlined';
import CreateOutlinedIcon from '@mui/icons-material/CreateOutlined';
import NotificationsIcon from '@mui/icons-material/NotificationsOutlined';
import PaidOutlinedIcon from '@mui/icons-material/PaidOutlined';
import {
  Badge,
  Box,
  Button,
  ClickAwayListener,
  Divider,
  IconButton,
  Modal,
  Typography,
} from '@mui/material';
import { useAcoRouter } from '@providers/hooks/AcoRouter';
import { useNotificationsContext } from '@providers/notifications/NotificationsContext';
import NewPostProvider from '@providers/post/NewPostContext';
import { HideOnMobile, OnDesktop, OnMobile } from '@providers/Responsive.provider';
import { useSession } from '@providers/user/SessionContext';
import axios from 'axios';
import clsx from 'clsx';
import React, { useState } from 'react';
import useSWR from 'swr';

import styles from './HeaderProfile.styles';

interface ProfileMenuOption {
  name: string;
  Icon: SvgIconComponent,
  onClick?: () => void,
  link?: string,
}

export const usersEnable = [12419, 207, 526, 5624, 13594, 7616, 11, 3705, 26256, 23208, 5788, 6093, 7143, 10410, 9, 4209, 5645, 15267, 2626, 24061, 25557, 25556, 25555, 2237];


const HeaderProfile: React.FC = () => {
  const { user, isLogged, signOut, loading } = useSession();
  const { allReadNotifications } = useNotificationsContext();
  const acoRouter = useAcoRouter();
  const [open, setOpen] = React.useState(false);
  const [openNotifications, setOpenNotifications] = React.useState(false);
  const [openModal, setOpenModal] = React.useState(false);
  const [openMore, setOpenMore] = React.useState(false);
  const [postOpen, setPostOpen] = useState(false);
  const userVerified = user && user.verified;
  const classes = styles();

  const url = '/api/user/notifications/unread/amount';
  const fetcher = (url: string) => axios.get<{ count: number }>(url).then((res) => res.data);
  const { data } = useSWR(`${url}`, fetcher);

  if (!isLogged || !user) {
    return (
      <Box className={classes.buttons}>
        <HideOnMobile>
          <Button className={classes.smallButtonIcon} onClick={() => acoRouter.register()}>
            <Typography variant='subtitle2' className={classes.name}>
              Registrarse
            </Typography>
          </Button>
        </HideOnMobile>
        <Button className={clsx(classes.smallButtonIcon, classes.loginButton)}
                onClick={() => acoRouter.login({ stayHere: true })}>
          <Typography variant='subtitle2' className={clsx(classes.name, 'login')}>
            Iniciar sesión
          </Typography>
        </Button>
      </Box>
    );
  }

  const paymentAccess = user && usersEnable.includes(user.id as number) && (user.paymentAccess.tickets || user.paymentAccess.trainings);

  const profileOptions: ProfileMenuOption[] = [
    { name: 'Mi Perfil', Icon: AccountCircleOutlined, link: (user && `/user/${user.id}`) },
    { name: 'Mis Consultas', Icon: ForumOutlined, link: (user && `/user/${user.id}?optionProfile=Ticket`) },
    { name: 'Mis Publicaciones', Icon: Assignment, link: (user && `/user/${user.id}?optionProfile=Post`) },
    { name: 'Mis Cupones', Icon: CardGiftcardOutlinedIcon, link: (user && `/user/${user.id}?optionProfile=Coupons`) },
    { name: 'Mis Capacitaciones', Icon: CoPresentOutlinedIcon, link: (user && '/trainings?typeTrainings=own') },
    { name: 'Administracion', Icon: PaidOutlinedIcon, link: (user && `/user/${user.id}/administration`) },
    { name: 'Cerrar sesión', Icon: ExitToAppOutlined, onClick: () => signOut() },
  ];

  const actionOptions: ProfileMenuOption[] = [
    {
      name: userVerified ? 'Mis Consultas' : 'Crear consulta',
      Icon: ForumOutlined,
      link: userVerified ? (user && `/user/${user.id}?optionProfile=Ticket`) : ('/tickets/new'),
    },
    {
      name: 'Crear publicación',
      Icon: ArticleOutlinedIcon,
      onClick: () => setPostOpen(true),
    },
  ];

  const handleClick = () => {
    setOpen((prev) => !prev);
  };

  const handleClickAway = () => {
    setOpen(false);
  };

  const handleOpenNotifications = () => {
    setOpenNotifications((show) => !show);
    setOpenMore(false);
  };

  const handleCloseNotifications = () => {
    setOpenNotifications(false);
  };

  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);

  const handleOpenMore = () => {
    setOpenMore((show) => !show);
    setOpenNotifications(false);
  };

  const handleCloseMore = () => {
    setOpenMore(false);
  };

  return (
    <Box display='flex' alignItems='center' justifyContent='space-between'>
      <OnDesktop>
        <ClickAwayListener onClickAway={handleCloseMore} disableReactTree>
          <div>
            {userVerified ?
              <ACOTooltip title={'Crear publicación'}>
                <Button className={classes.smallButtonIcon}
                        onClick={() => setPostOpen(true)}>
                  <CreateOutlinedIcon />
                </Button>
              </ACOTooltip>
              :
              <Button className={classes.smallButtonIcon} onClick={handleOpenMore}>
                <CreateOutlinedIcon />
              </Button>
            }
            <div className={classes.profileMenuContainer}>
              {openMore ? (
                <div className='actionsMenu'>
                  {actionOptions.map((option, i) => (
                    <div key={i} className='option' onClick={option.onClick}>
                      {option.link && <ACOLink href={option.link} style={{
                        textDecoration: 'none',
                        color: '#2270A8',
                        display: 'flex',
                        alignItems: 'end',
                      }}>
                        <option.Icon />
                        {option.name}
                      </ACOLink>}

                      {!option.link &&
                        <>
                          <option.Icon />
                          {option.name}
                        </>
                      }
                    </div>
                  ))}

                </div>
              ) : null}
            </div>
          </div>
        </ClickAwayListener>

        <ClickAwayListener onClickAway={handleCloseNotifications} disableReactTree>
          <div>
            <ACOTooltip title='Notificaciones' arrow>
              <Button className={classes.smallButtonIcon} onClick={handleOpenNotifications}>
                <>
                  {data?.count > 0 && !allReadNotifications ?
                    <Badge color='error' badgeContent={data.count} max={10}> <NotificationsIcon /> </Badge>
                    :
                    <NotificationsIcon />
                  }
                </>
              </Button>
            </ACOTooltip>
            <div className={classes.profileMenuContainer}>
              {openNotifications ? (
                <div className='profileNotifications'>
                  <NotificationsLayout />
                </div>
              ) : null}
            </div>
          </div>
        </ClickAwayListener>

        <ClickAwayListener onClickAway={handleClickAway} disableReactTree>
          <div className={classes.profile}>
            {loading || !user ? 'Cargando...' : (
              <Button className={classes.button} onClick={handleClick}>
                <Avatar src={user.avatarUrl} alt={user.fullName} size={{ desktop: 41, mobile: 26 }}
                        classes={classes.avatar} />
                {/*<Typography variant='subtitle2' className={classes.name}>
                  {user.name} {user.lastName?.charAt(0)}. (@{user.nickname})
                </Typography>
                <KeyboardArrowDown className={classes.icon} />*/}
              </Button>
            )}
            <div className={classes.profileMenuContainer}>
              {open ? (
                <div className='profileMenu'>
                  {profileOptions.filter(option => option.name !== 'Administracion' || paymentAccess).map((option, i) => (
                    <div key={i} className='option' onClick={option.onClick}>
                      {option.link && <ACOLink href={option.link} style={{
                        textDecoration: 'none',
                        color: '#2270A8',
                        display: 'flex',
                        alignItems: 'end',
                      }}>
                        <option.Icon />
                        {option.name}
                      </ACOLink>}

                      {!option.link &&
                        <>
                          <option.Icon />
                          {option.name}
                        </>
                      }
                    </div>
                  ))}
                </div>
              ) : null
              }
            </div>
          </div>
        </ClickAwayListener>
      </OnDesktop>

      <OnMobile>
        <ClickAwayListener onClickAway={handleCloseMore} disableReactTree>
          <div>
            <Button onClick={handleOpenMore} className={classes.moreButton}>
              <AddCircleOutlineOutlinedIcon />
            </Button>
            <div className={classes.profileMenuContainer}>
              {openMore ? (
                <div className='profileMore'>
                  {actionOptions.map((option, i) => (
                    <>
                      {i > 0 && <Divider />}
                      <Button onClick={option.link ? () => acoRouter.router.push(option.link) : option.onClick}
                              startIcon={<option.Icon color='primary' />}
                              className={classes.profileMoreButton} variant='text'
                              color='primary' size='large'>
                        {option.name}
                      </Button>
                    </>
                  ))}
                </div>
              ) : null}
            </div>
          </div>
        </ClickAwayListener>
        <ACOLink href={`/user/${user.id}/notifications`}>
          <IconButton size='small' className={classes.smallButtonIcon}>
            <>
              {data?.count > 0 && !allReadNotifications ?
                <Badge color='error' variant='dot'>
                  <NotificationsIcon className={classes.icon} fontSize='inherit' />
                </Badge>
                :
                <NotificationsIcon className={classes.icon} fontSize='inherit' />
              }
            </>
          </IconButton>
        </ACOLink>
        <div onClick={handleOpenModal}>
          {loading || !user ? 'Cargando...' :
            <Avatar src={user.avatarUrl} alt={user.fullName} size={{ desktop: 41, mobile: 26 }}
                    classes={classes.avatar} />}
        </div>
      </OnMobile>

      <NewPostProvider>
        <CreatePost props={{ open: postOpen, onClose: () => setPostOpen(false) }} />
      </NewPostProvider>

      <Modal open={openModal} onClose={handleCloseModal}>
        <Box className={classes.modalProfile}>
          <Box display='flex' alignItems='center' justifyContent='space-between' marginBottom='8px' width='min-content'>
            <Typography variant='h6'>{user.fullName}</Typography>
            <Button startIcon={<CloseIcon />} disableRipple size={'small'} style={{ minWidth: '0px' }}
                    onClick={handleCloseModal} />
          </Box>
          <Divider />
          <div className={classes.profileMenuContainer}>
            <div className='profileMobileMenu'>
              {profileOptions.filter(option => option.name !== 'Administracion' || paymentAccess).map((option, i) => (
                <div key={i} className='option' onClick={option.onClick}>
                  {option.link && <ACOLink href={option.link} style={{ textDecoration: 'none', display: 'flex' }}>
                    <option.Icon />
                    {option.name}
                  </ACOLink>}
                  {!option.link &&
                    <>
                      <option.Icon />
                      {option.name}
                    </>
                  }
                </div>
              ))}
            </div>
          </div>
        </Box>
      </Modal>

    </Box>
  );

};

export default HeaderProfile;
